<template>
	<div class="hotel-details">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/hotel-display',query:{top_keyword:top_keyword}}">酒店展示
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{pagename}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="thumb-example">
					<!-- swiper1 -->
					<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop" v-if="bannerarr.length > 1" >
						<swiper-slide v-for="(item,index) in bannerarr" :key="index">
							<img :src="item.uri" alt="">
						</swiper-slide>
					</swiper>
					
					<div class="right-box">
						<div class="swiper-button-prev" slot="button-prev">
							<div class="hoverImg">
								<img src="../../assets/images/home/xiajiantou.png" alt="">
							</div>
							<div class="Img">
								<img src="../../assets/images/home/shangjiantou.png" alt="">
							</div>
						</div>
						<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs" v-if="bannerarr.length > 1">
							<swiper-slide v-for="(item,index) in bannerarr" :key="index">
								<img :src="item.uri" alt="">
							</swiper-slide>
						</swiper>
						<div class="swiper-button-next" slot="button-next">
							<div class="hoverImg">
								<img src="../../assets/images/home/xiajiantou.png" alt="">
							</div>
							<div class="Img">
								<img src="../../assets/images/home/shangjiantou.png" alt="">
							</div>
						</div>
					</div>
					<!-- 屏幕过小时 -->
					<div class="right-box1">
						<div class="swiper-button-prev swiper-button-prev1" slot="button-prev">
							<div class="hoverImg">
								<img src="../../assets/images/home/xiajiantou.png" alt="">
							</div>
							<div class="Img">
								<img src="../../assets/images/home/shangjiantou.png" alt="">
							</div>
						</div>
						<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs1" ref="swiperThumbs1" v-if="bannerarr.length > 1">
							<swiper-slide v-for="(item,index) in bannerarr" :key="index">
								<img :src="item.uri" alt="">
							</swiper-slide>
						</swiper>
						<div class="swiper-button-next swiper-button-next1" slot="button-next">
							<div class="hoverImg">
								<img src="../../assets/images/home/xiajiantou.png" alt="">
							</div>
							<div class="Img">
								<img src="../../assets/images/home/shangjiantou.png" alt="">
							</div>
						</div>
					</div>
				</div>
				<div class="hotel-info">
					<span class="hotel-name">{{pagename}}</span>
					<div class="hotel-address">
						<div class="hot-item">
							<img src="../../assets/images/home/zhuoshu.png" alt="">
							<div class="item-text">容纳桌数：<span>{{infolist.desk_num}}</span></div>
						</div>
						<div class="hot-item">
							<img src="../../assets/images/home/weizhi.png" alt="">
							<div class="item-text">酒店地址：<span>{{infolist.address}}</span></div>
						</div>
					</div>
				</div>
				<div class="page-title">
					<div class="line"></div>
					<span>宴会厅介绍</span>
				</div>
				<div class="introduce" v-html="infolist.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import {
		getjiudianxqList
	} from "@/api/joggle.js"
	export default {
		name:'Hotel-Details',
		components: {
			BannerOther,
			Sidder,
			swiper,
			swiperSlide,
		},
		data() {
			return {
				tab_selectIndex: 10,
				img_url: '',
				chinese_name: '酒店展示',
				english_name: 'HOTEL DISPLAY',
				pagename: '', // 导航栏名称、酒店名称
				swiperOptionTop: { // 无限循环必须两张以上
					loop: true,
					loopedSlides: 8,
					slidesPerView: 1,
					direction: 'horizontal',
					spaceBetween: 10,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
				},
				swiperOptionThumbs: {
					loop: true,
					direction: 'vertical',
					loopedSlides: 8, 
					spaceBetween: 10,
					slidesPerView: 'auto',
					slideToClickedSlide: true,
					
				},
				swiperOptionThumbs1:{
					loop: true,
					direction: 'horizontal',
					loopedSlides: 8,
					slidesPerView: 3,
					spaceBetween: 10,
					// slidesPerView: 'auto',
					slideToClickedSlide: true,
				},
				hotelid:0, // 酒店id
				bannerarr:[],
				infolist:{},
				screenWidth: document.body.clientWidth, //实时屏幕宽度
				top_keyword:''
			}
		},
		watch:{
			screenWidth(val,oldval){
			}
		},
		mounted() {
			var that = this
			// 监听屏幕大小
			window.onresize = () => {
				window.screenWidth = document.body.clientWidth
				that.screenWidth = window.screenWidth;
			}
		},
		activated() {
			var that = this
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.hotel_image;
			}
			that.pagename = that.$route.query.pagename;
			// 修改浏览器名称
			document.title = that.pagename + '-酒店展示-MG影像';
			that.hotelid =  that.$route.query.hotelid;
			that.top_keyword = that.$route.query.top_keyword;
			that.gethotelDetails();
			that.options = JSON.parse(localStorage.getItem('options'));
			that.options.forEach((item,index)=>{
				if(item.name == that.chinese_name){
					that.tab_selectIndex = index
				}
			})
		},
		methods: {
			// 获取详情
			gethotelDetails(screenWidth){
				var that = this
				var obj = {
					id:that.hotelid
				}
				getjiudianxqList(obj).then(res => {
					var data = res.data;
					that.bannerarr = data.img;
					that.infolist = data;
					that.swiperOptionTop.loopedSlides = that.bannerarr.length;
					that.swiperOptionThumbs.loopedSlides = that.bannerarr.length;
					that.swiperOptionThumbs1.loopedSlides = that.bannerarr.length;
					if(that.screenWidth > 685){
						this.$nextTick(() => {
							var swiperTop = this.$refs.swiperTop.swiper;
							var swiperThumbs = this.$refs.swiperThumbs.swiper;
							swiperTop.controller.control = swiperThumbs;
							swiperThumbs.controller.control = swiperTop;
						})
					}else{
						this.$nextTick(() => {
							var swiperTop = this.$refs.swiperTop.swiper;
							var swiperThumbs1 = this.$refs.swiperThumbs1.swiper;
							swiperTop.controller.control = swiperThumbs1;
							swiperThumbs1.controller.control = swiperTop;
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/public.scss';
	@import '../../assets/css/hotel-details.scss';
	
	
	
	 
</style>
